import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AdminContactsComponent } from './components/contacts/admin-contacts/admin-contacts.component';
import { AdminPositionsComponent } from './components/positions/admin-positions/admin-positions.component';
import { ModifyPositionComponent } from './components/positions/modify-position/modify-position.component';
import { AddPositionComponent } from './components/positions/add-position/add-position.component';
import { AdminCandidatesComponent } from './components/candidates/admin-candidates/admin-candidates.component';
import { HighlightDataComponent } from './components/highlight-data/highlight-data.component';
import { RibbonYearsComponent } from './components/ribbon-years/ribbon-years.component';
import { ServicesComponent } from './components/services/services.component';
import { ServicesExtraComponent } from './components/services-extra/services-extra.component';
import { TechnologiesComponent } from './components/technologies/technologies.component';
import { ModifyServiceExtraComponent } from './components/services-extra/modify-service-extra/modify-service-extra.component';
import { AddServiceExtraComponent } from './components/services-extra/add-service-extra/add-service-extra.component';
import { ModifyServiceComponent } from './components/services/modify-service/modify-service.component';
import { AddServiceComponent } from './components/services/add-service/add-service.component';
import { AddTechComponent } from './components/technologies/add-tech/add-tech.component';
import { ModifyTechComponent } from './components/technologies/modify-tech/modify-tech.component';
import { LocationsComponent } from './components/locations/locations.component';
import { ModifyLocationComponent } from './components/locations/modify-location/modify-location.component';
import { AddLocationComponent } from './components/locations/add-location/add-location.component';
import { ModifyRibbonComponent } from './components/ribbon-years/modify-ribbon/modify-ribbon.component';
import { ClientsComponent } from './components/clients/clients.component';
import { AddClientsComponent } from './components/clients/add-clients/add-clients.component';
import { ModifyClientsComponent } from './components/clients/modify-clients/modify-clients.component';
import { LoginComponent } from './login/login.component';
import { ModifyClientsImgComponent } from './components/clients/modify-clients-img/modify-clients-img.component';
import { AddToolsComponent } from './components/technologies/add-tools/add-tools.component';
import { ToolsComponent } from './components/tools/tools.component';
import { ModifyToolsComponent } from './components/tools/modify-tools/modify-tools.component';
import { AddToolItemComponent } from './components/tools/add-tool-item/add-tool-item.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthguardGuard } from './login/authguard.guard';
import { TermsPrivacyComponent } from './components/terms-privacy/terms-privacy.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

export const routes: Routes = [
 
  {
    path: "Ubicaciones",
    component: LocationsComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "ModificarUbicacion",
    component: ModifyLocationComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "AgregarUbicacion",
    component: AddLocationComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "Contactos",
    component: AdminContactsComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "Clientes",
    component: ClientsComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "AgregarCliente",
    component: AddClientsComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "ModificarCliente",
    component: ModifyClientsComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "ModificarClienteLogo",
    component: ModifyClientsImgComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "Vacantes",
    component: AdminPositionsComponent,
    canActivate: [AuthguardGuard] 
  },
 
  {
    path: "ModificarVacante",
    component: ModifyPositionComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "AgregarVacante",
    component: AddPositionComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "Candidatos",
    component: AdminCandidatesComponent,
    canActivate: [AuthguardGuard] 
  },

  {
    path: "AnuncioHome",
    component: HighlightDataComponent,
    canActivate: [AuthguardGuard] 
  },

  {
    path: "CambiarPassword",
    component: ChangePasswordComponent,
    canActivate: [AuthguardGuard] 
  },

  {
    path: "Aviso",
    component: TermsPrivacyComponent,
    canActivate: [AuthguardGuard] 
  },

  {
    path: "Indicadores",
    component: RibbonYearsComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "ModificarIndicadores",
    component: ModifyRibbonComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "Servicios",
    component: ServicesComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "ModificarServicio",
    component: ModifyServiceComponent,
    canActivate: [AuthguardGuard] 
    
  },
  {
    path: "AgregarServicio",
    component: AddServiceComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "ServiciosAdicionales",
    component: ServicesExtraComponent,
    canActivate: [AuthguardGuard] 
  },
  {
    path: "ModificarServicioAdicional",
    component: ModifyServiceExtraComponent,
    canActivate: [AuthguardGuard] 
    
  },
  {
    path: "AgregarServicioAdicional",
    component: AddServiceExtraComponent,
    canActivate: [AuthguardGuard] 
  },
  
  {
    path: "Tecnologias",
    component: TechnologiesComponent,
    canActivate: [AuthguardGuard] 
  },

  {
    path: "AgregarTecnologia",
    component: AddTechComponent,
    canActivate: [AuthguardGuard] 
  },

  {
    path: "ModificarTecnologia",
    component: ModifyTechComponent,
    canActivate: [AuthguardGuard] 
  },

  {
    path: "SeleccionarHerramientas",
    component: AddToolsComponent
  },

  {
    path: "Herramientas",
    component: ToolsComponent,
    canActivate: [AuthguardGuard] 
  },

  {
    path: "AgregarHerramienta",
    component: AddToolItemComponent
  },

  {
    path: "ModificarHerramienta",
    component: ModifyToolsComponent,
    canActivate: [AuthguardGuard] 
  },

  { 
    path: '', 
    component: DashboardComponent,
    canActivate: [AuthguardGuard] 
  },

  {
    path: "Login",
    component: LoginComponent
  },

  {
    path: "",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full"
  }
];

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: "enabled"
};

@NgModule({
  imports: [RouterModule, RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
