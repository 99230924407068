import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RemoveModalComponent } from '../components/modals/remove-modal/remove-modal.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class FullProcessService {

  constructor(private router: Router,
    public dialog: MatDialog) { }


  removeItem(idPosition, table, title) {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      width: '500px',
      data: { idPosition: idPosition, table: table, title: title },
      disableClose: true
    });

    return dialogRef;

  }

}
