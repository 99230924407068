import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesExtraComponent } from './services-extra.component';
import {
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTableModule,
  MatInputModule,
  MatFormFieldModule,  
} from "@angular/material";
import { RouterModule } from '@angular/router';
import { ModifyServiceExtraComponent } from './modify-service-extra/modify-service-extra.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddServiceExtraComponent } from './add-service-extra/add-service-extra.component';

@NgModule({
  declarations: [ServicesExtraComponent, ModifyServiceExtraComponent, AddServiceExtraComponent],
  exports: [ServicesExtraComponent, ModifyServiceExtraComponent,AddServiceExtraComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    RouterModule,
    MatDialogModule,
    MatTableModule,
  ]
})
export class ServicesExtraModule { }
