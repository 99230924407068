import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from "@angular/material";
import { ConnectionService } from "src/app/services/connection.service";
import { Router } from "@angular/router";
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { RemoveModalComponent } from '../modals/remove-modal/remove-modal.component';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {
  noRows: boolean = false;
  loading: boolean = true;

  toolsData: any = [];
  toolsData2: any = [
    {"id":"1","name":"Access"},
    {"id":"2","name":"ALM-HP"},
    {"id":"3","name":"App Service"},
    {"id":"4","name":"Artus"},
    {"id":"5","name":"ASP\/PHP"},
    {"id":"6","name":"Atlassian Jira"},
    {"id":"7","name":"Automation"},
    {"id":"8","name":"Business Objects"},
    {"id":"9","name":"CodeOnTime"},
    {"id":"10","name":"Cognos"},
    {"id":"11","name":"Control-m"},
    {"id":"12","name":"Crystal Reports"},
    {"id":"13","name":"Datastage"},
    {"id":"14","name":"DB2"},
    {"id":"15","name":"DevOps"},
    {"id":"16","name":"Enterprise Architect"},
    {"id":"17","name":"HTML\/XML\/XSL"},
    {"id":"18","name":"Informatica PWC"},
    {"id":"19","name":"ISTQB"},
    {"id":"20","name":"Java"},
    {"id":"21","name":"Javascript\/VB Script"},
    {"id":"22","name":"Logic APP"},
    {"id":"23","name":"Mantis"},
    {"id":"24","name":"Microstrategy"},
    {"id":"25","name":"Mobile Apps"},
    {"id":"26","name":"MySQL"},
    {"id":"27","name":"Nativescript"},
    {"id":"28","name":"Oracle"},
    {"id":"29","name":"Oracle Apex"},
    {"id":"30","name":"Oracle BI"},
    {"id":"31","name":"Oracle Data Integrator"},
    {"id":"32","name":"Oracle Forms"},
    {"id":"33","name":"Oracle Publisher\/ Reports"},
    {"id":"34","name":"Oracle SOA"},
    {"id":"35","name":"Oracle Warehouse Builder"},
    {"id":"36","name":"PL\/SQL"},
    {"id":"37","name":"Postgres"},
    {"id":"38","name":"Pro*C"},
    {"id":"39","name":"Rest APIs"},
    {"id":"40","name":"Sagent"},
    {"id":"41","name":"Selenium"},
    {"id":"42","name":"SQL Server"},
    {"id":"43","name":"SQL Server Integration Services"},
    {"id":"44","name":"SQL Server Reporting Services"},
    {"id":"45","name":"Sybase"},
    {"id":"46","name":"Tableau"},
    {"id":"47","name":"UC4\/Automic"},
    {"id":"48","name":"VB .NET\/C# .NET"},
    {"id":"49","name":"VB 6.0"},
    {"id":"50","name":"Web Apps"}
    ];

  displayedColumns: string[] = ["name", "edit", "remove"];
  dataSource: any;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private store: StorageValuesService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadContent();
    // this.loadFake();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadFake() {
    this.loading = false;
   
    this.dataSource = new MatTableDataSource(this.toolsData2);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.noRows = false;
  }

  loadContent() {

    this.loading = true;
    
    this.store.deleteStorage("idPosition");
    
    let obj = {
      table: "tools"
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data tools is:", data);
        this.loading = false;
        if (!data.error) {
          this.toolsData = data.info;
          this.dataSource = new MatTableDataSource(this.toolsData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }

  addNew() {
    this.router.navigate(["/AgregarHerramienta"]);
  }


  modifyTool(idPosition) {
    console.log("idPosition in modifyTool", idPosition);
    // this.store.setSelectedPosition(idPosition);
    this.store.setStorage("idPosition",idPosition);
    this.router.navigate(["/ModificarHerramienta"]);
  }

  removeTool(idPosition): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      width: '500px',
      data: {idPosition: idPosition, table: 'tools', title:'Herramientas en Tecnologías'},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.status){
        this.loadContent();
      }
      
    });
  }
}

