import { 
  Component,
  OnInit,
  Output,
  HostListener,
  EventEmitter,
  HostBinding,
  Input } from '@angular/core';

@Component({
  selector: 'app-admin-mobile-nav',
  templateUrl: './admin-mobile-nav.component.html',
  styleUrls: ['./admin-mobile-nav.component.scss']
})
export class AdminMobileNavComponent implements OnInit {

  mobileNav: boolean = false;

  @Output() toggle: EventEmitter<any> = new EventEmitter();

  @HostListener("click")
  click() {
    this.toggle.emit();
  }

  @HostBinding("class.is-closed")
  @Input()
  isClosed = false;

  constructor() {}

  ngOnInit() {}
}

