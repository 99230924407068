<div class=" db-sidebar" id="mobileNav">
    <div class="list-group">

        <ng-container>
            <span>rms.julle@gmail.com</span>
            <a [routerLink]="['/CambiarPassword']" class="red-span small">Cambiar contraseña</a>
        </ng-container>

        <a [routerLink]="['/Vacantes']" routerLinkActive="active" class="list-group-item list-group-item-action">Vacantes</a>

        <a [routerLink]="['/Candidatos']" routerLinkActive="active" class="list-group-item list-group-item-action">Candidatos</a>

        <a [routerLink]="['/Contactos']" routerLinkActive="active" class="list-group-item list-group-item-action">Contactos</a>

        <a [routerLink]="['/Ubicaciones']" routerLinkActive="active" class="list-group-item list-group-item-action">Ubicaciones</a>

        <a [routerLink]="['/AnuncioHome']" routerLinkActive="active" class="list-group-item list-group-item-action">Anuncio Home</a>

        <a [routerLink]="['/Aviso']" routerLinkActive="active" class="list-group-item list-group-item-action">Aviso de Privacidad</a>

        <a [routerLink]="['/Indicadores']" routerLinkActive="active" class="list-group-item list-group-item-action">Indicadores</a>

        <a [routerLink]="['/Clientes']" routerLinkActive="active" class="list-group-item list-group-item-action">Clientes</a>

        <a [routerLink]="['/Servicios']" routerLinkActive="active" class="list-group-item list-group-item-action">Servicios</a>

        <a [routerLink]="['/ServiciosAdicionales']" routerLinkActive="active" class="list-group-item list-group-item-action">Servicios Adicionales</a>

        <a [routerLink]="['/Tecnologias']" routerLinkActive="active" class="list-group-item list-group-item-action">Tecnologías</a>

        <a [routerLink]="['/Herramientas']" routerLinkActive="active" class="list-group-item list-group-item-action">Herramientas en Tecnologías</a>

        <button type="button" class="btn btn-block red-outline w-100 mw-100 fs-6 mt-3" (click)="logout()">Cerrar Sesión</button>



    </div>
</div>