import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsComponent } from './clients.component';
import { ModifyClientsComponent } from './modify-clients/modify-clients.component';
import { MatDialogModule, MatProgressSpinnerModule, MatInputModule, MatFormFieldModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { AddClientsComponent } from './add-clients/add-clients.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModifyClientsImgComponent } from './modify-clients-img/modify-clients-img.component';

@NgModule({
  declarations: [ClientsComponent, ModifyClientsComponent, AddClientsComponent, ModifyClientsImgComponent],
  exports: [ClientsComponent, ModifyClientsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressSpinnerModule,
    RouterModule,    
    MatDialogModule,
  ]
})
export class ClientsModule { }
