import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,
  MatListModule,
  MatRadioModule,  
} from "@angular/material";
import { MatTableModule } from "@angular/material/table";
import { ModifyPositionComponent } from './modify-position.component';

@NgModule({
  declarations: [ModifyPositionComponent],
  exports: [ModifyPositionComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    RouterModule,
    MatPseudoCheckboxModule,
    MatDialogModule,
    MatListModule,
    MatRadioModule
  ]
})
export class ModifyPositionModule { }
