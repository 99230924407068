<div class="container">
    <div class="row ">
        <div class="col-12 col-sm-6">
            <h3>Ubicaciones de Oficinas</h3>
        </div>
        <div class="col-12 col-sm-6">
            <button (click)="addLocation()" type="button" class="btn red">Nueva Ubicación</button>
        </div>
    </div>

    <div class="row" [ngClass]="{ 'mt-5': loading || noRows }">
        <div class="col-12">
            <mat-spinner *ngIf="loading"></mat-spinner>
        </div>
        <div class="col-12 text-center" *ngIf="noRows">
            <p>No hay ubicaciones</p>
        </div>
    </div>
    <div class="row" *ngIf="!noRows">
        <div class="col-12 col-sm-6 col-md-4 mt-5" *ngFor="let each of locations">
            <div class="card">
                <div class="card-header">
                    <div class="text-right">
                        <div class="icon-modify" (click)="modifyLocation(each.id)"><span class="icon-pencil"></span></div>
                        <div class="icon-modify" (click)="removeLocation(each.id)"><span class="icon-bin2"></span></div>
                    </div>
                </div>
                <div class="card-body">
                    <h5 class="card-title">{{ each.city }}</h5>
                    <hr />
                    <div class="mt-4" *ngIf="each.building">
                        <h6 class="card-subtitle text-muted">Edificio</h6>
                        <p class="card-text">{{ each.building }}</p>
                    </div>
                    <div class="mt-4">
                        <h6 class="card-subtitle text-muted">Dirección</h6>
                        <p class="card-text">{{ each.address }}</p>
                    </div>
                    <div class="mt-4">
                        <h6 class="card-subtitle text-muted">Url del Mapa de Google</h6>
                        <a [href]="each.mapUrl" target="_blank" class="card-url">{{ each.mapUrl }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>