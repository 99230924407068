
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";
import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import * as LabelsField from "../../../../assets/labels/labels.json";
import { GeneralModalComponent } from '../../modals/general-modal/general-modal.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-add-tech',
  templateUrl: './add-tech.component.html',
  styleUrls: ['./add-tech.component.scss']
})
export class AddTechComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  successSubmition: boolean = false;
  error: {};
  
  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  
  positions: any = [];
  icons: any;

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.icons = (<any>LabelsField).icons;
    this.userForm = this.formBuilder.group({
      techName: ["", Validators.required],
      techIcon: ["", Validators.required],
      });
  
  }
  
  back(){
    this.router.navigate(["/Tecnologias"]);
  }

  get f() {
    return this.userForm.controls;
  }

  displayFn(item) {
    if (item) {
      return item.nombre;
    }
  }


 
  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;
      
      this.connectionService
        .insertService(this.userForm.value, "addTechnology.php")
        .subscribe(data => {
          console.log("data addTechnology is:", data);
          this.loading = false;
          if (!data.error) {
            // this.store.setSelectedPosition(data.idInserted);
            this.store.setStorage("idPosition",data.idInserted);
            this.userForm.reset();
            this.sent = true;
            
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Ahora selecciona las herramientas relacionadas a la tecnología."
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/SeleccionarHerramientas"]);             
            });

          } else {
            this.errorResponse = true;
          }
        });

        
    }
  }


}
