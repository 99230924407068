import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesComponent } from './services.component';
import { ModifyServiceComponent } from './modify-service/modify-service.component';
import { AddServiceComponent } from './add-service/add-service.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatDialogModule, MatRadioModule } from '@angular/material';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ServicesComponent, ModifyServiceComponent, AddServiceComponent],
  exports: [ServicesComponent, ModifyServiceComponent, AddServiceComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatRadioModule,
    MatDialogModule,
  ]
})
export class ServicesModule { }
