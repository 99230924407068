import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RibbonYearsComponent } from './ribbon-years.component';
import { MatProgressSpinnerModule, MatInputModule, MatFormFieldModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModifyRibbonComponent } from './modify-ribbon/modify-ribbon.component';

@NgModule({
  declarations: [RibbonYearsComponent, ModifyRibbonComponent],
  exports: [RibbonYearsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ]
})
export class RibbonYearsModule { }
