
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,  
} from "@angular/material";
import { MatTableModule } from "@angular/material/table";
import { AdminCandidatesComponent } from './admin-candidates.component';

@NgModule({
  declarations: [AdminCandidatesComponent],
  exports: [AdminCandidatesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    RouterModule,
    MatPseudoCheckboxModule,
    MatDialogModule,
  ]
})
export class AdminCandidatesModule { }
