<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="close-icon" (click)="close()"></div>
        </div>
    </div>

    <div class="row">

        <div class="col-12">
            <div class="text-center subtitle subtitle-2">
                <div><span class="icon-checkmark2"></span> </div>
                <div>{{data}}</div>
            </div>

        </div>
    </div>
</div>