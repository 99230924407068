<section>
    <div>
        <div class="container">
            <div class="loading" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <h2 class="subhead-title">Modificar Ubicación</h2>
                </div>
            </div>
            <div class="row">
                <div class="offset-lg-2 col-lg-8 offset-md-1 col-md-10">

                    <div class="alert alert-danger" role="alert" *ngIf="errorResponse">
                        Hubo un problema, intentalo más tarde.
                    </div>
                    <form class="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()" *ngIf="!sent">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="locationCity">Ciudad, Estado</label>
                                    <input formControlName="locationCity" type="text" class="form-control" id="locationCity" required />

                                    <div *ngIf="submitted && f.locationCity.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.locationCity.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label for="locationBuilding">Edificio / Piso(s)</label>
                                    <input formControlName="locationBuilding" type="text" class="form-control" id="locationBuilding" required />

                                    <div *ngIf="submitted && f.locationBuilding.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.locationBuilding.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="locationAddress">Dirección Completa</label>
                                    <input formControlName="locationAddress" type="text" class="form-control" id="locationAddress" required />

                                    <div *ngIf="submitted && f.locationAddress.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.locationAddress.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="locationMap">Url del Google Maps</label>
                                    <input formControlName="locationMap" type="text" class="form-control" id="locationMap" required />

                                    <div *ngIf="submitted && f.locationMap.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.locationMap.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="btn red mt-3">Modificar</button>
                                <button class="btn m-auto red-outline" (click)="back()">Cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>