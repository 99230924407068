import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";
import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import * as LabelsField from "../../../../assets/labels/labels.json";
import { GeneralModalComponent } from '../../modals/general-modal/general-modal.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  successSubmition: boolean = false;
  error: {};
  
  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  
  positions: any = [];
  icons: any;

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.icons = (<any>LabelsField).icons;
    this.userForm = this.formBuilder.group({
      serviceName: ["", Validators.required],
      serviceDesc: ["", Validators.required],
      serviceIcon: ["", Validators.required],
      });
  
  }

  back(){
    this.router.navigate(["/Servicios"]);
  }

  get f() {
    return this.userForm.controls;
  }

  displayFn(item) {
    if (item) {
      return item.nombre;
    }
  }


 

  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;
      
      
      this.connectionService
        .insertService(this.userForm.value, "addService.php")
        .subscribe(data => {
          console.log("data addService is:", data);
          this.loading = false;
          if (!data.error) {
            this.userForm.reset();
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Se agregó exitosamente"
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/Servicios"]);
              
            });
          } else {
            this.errorResponse = true;
          }
        });

        
    }
  }
}
