<div class="container">
    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="row ">
        <div class="col-12 col-sm-6">
            <h3>Indicadores</h3>
        </div>
        <div class="col-12 col-sm-6" *ngIf="!editMode">
            <button (click)="modify()" type="button" class="btn red">Modificar</button>
        </div>
    </div>

    <div class="row" *ngIf="editMode">
        <div class="col-12 text-center">
            <h2 class="subhead-title mb-5">Modificar Datos</h2>
        </div>
    </div>
</div>

<div class="container">
    <div class="row justify-content-center ribbon red">

        <div class="col-3 col-md-1">
            <div class="subtitle subtitle-3 text-center">
                {{ribbonData.years}}
            </div>

        </div>
        <div class="col-9 col-md-5">
            <div class="subtitle subtitle-3 text-center">
                {{ribbonData.yearsDesc}}
            </div>

        </div>
        <div class="col-3 col-md-1">
            <div class="subtitle subtitle-3 text-center">
                {{ribbonData.devs}}
            </div>

        </div>
        <div class="col-9 col-md-5">
            <div class="subtitle subtitle-3 text-center">
                {{ribbonData.devsDesc}}
            </div>

        </div>

    </div>


</div>