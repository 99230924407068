import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConnectionService } from "src/app/services/connection.service";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
} from "@angular/material";
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { RemoveModalComponent } from '../modals/remove-modal/remove-modal.component';
import { FullProcessService } from 'src/app/services/full-process.service';


@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  noRows: boolean = false;
  loading: boolean = true;
  locations: any = [];
  displayedColumns: string[] = ["name", "description", "location"];
  dataSource: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private store: StorageValuesService,
    private process: FullProcessService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadContent();
    // this.loadFake();
  }

  loadFake() {
    this.loading = false;
    this.locations = [
      { "id": "1", "city": "MONTERREY, NUEVO LEÓN", "building": "Torre Avalanz - Piso 17 y 18", "address": "Av. Batallón de San Patricio #109 Sur, Col. Valle Oriente, San Pedro Garza García, N.L.", "mapUrl": "https:\/\/goo.gl\/maps\/dj9nh1zdxu9Bz4zM8" },
      { "id": "2", "city": "TEPIC, NAYARIT", "building": "", "address": "San Luis #297 Norte, Col. Centro, Tepic, Nayarit.", "mapUrl": "https:\/\/goo.gl\/maps\/U262GxEtTPZp5HHeA" },
      { "id": "3", "city": "MÉXICO, D.F.", "building": "Plaza Carso", "address": "Calle Lago Zurich #219, Col. Ampliación Granada, Del. Miguel Hidalgo, México, D.F.", "mapUrl": "https:\/\/goo.gl\/maps\/WBhywTv5GBx5CSQGA" }
    ];
  }



  loadContent() {

    this.store.deleteStorage("idPosition");

    let obj = {
      table: "locations"
    };

    console.log("obj", obj);

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data getLocations is:", data);
        this.loading = false;
        if (!data.error) {
          this.locations = data.info;

          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addLocation() {
    this.router.navigate(["/AgregarUbicacion"]);
  }

  removeLocation(idPosition) {
    const dialogRef = this.process.removeItem(idPosition, 'locations', 'Ubicación');
    dialogRef.afterClosed().subscribe(result => {
      if (result.status) {
        this.loadContent();
      }
    });

  }

  modifyLocation(idPosition) {
    console.log("idPosition in modifyLocation", idPosition);
    // this.store.setSelectedPosition(idPosition);
    this.store.setStorage("idPosition", idPosition);
    this.router.navigate(["/ModificarUbicacion"]);
  }
}
