import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";

import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { MatDialog } from "@angular/material";
import { GeneralModalComponent } from "../../modals/general-modal/general-modal.component";

@Component({
  selector: 'app-modify-clients',
  templateUrl: './modify-clients.component.html',
  styleUrls: ['./modify-clients.component.scss']
})
export class ModifyClientsComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  error: {};
  selectedPositionId: any;

  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  positions: any = [];
  positionData: any = [];

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      id: ["", ""],
      clientName: ["", Validators.required],
      });

    this.loadContent();
    // this.loadFake();
  }

  back(){
    this.router.navigate(["/Clientes"]);
  }


  get f() {
    return this.userForm.controls;
  }

  displayFn(item) {
    if (item) {
      return item.nombre;
    }
  }




  loadFake(){
    
    this.selectedPositionId = '2';
    this.positionData = [
      {"id":"2","city":"TEPIC, NAYARIT","building":"","address":"San Luis #297 Norte, Col. Centro, Tepic, Nayarit.","mapUrl":"https:\/\/goo.gl\/maps\/U262GxEtTPZp5HHeA"},
      ];
    this.positionData = this.positionData[0];
    console.log("data getpositionData is:", this.positionData);

    this.loadDataForm();
  }

  loadContent() {
    // this.selectedPositionId = this.store.getSelectedPosition().value;
    this.selectedPositionId = this.store.getStorage("idPosition");
    console.log("this.selectedPositionId", this.selectedPositionId);
    
    
    this.loadPositionData();
    
  }

  loadPositionData(){
    this.loading = true;
    this.errorResponse = false;
    let obj = {
      idPosition: this.selectedPositionId,
      table: "clients"
    };
    this.connectionService.getService("getItemData.php", obj).subscribe(data => {
      console.log("data getPositionData is:", data);
      
      if (!data.error) {
        this.positionData = data.info[0];
        
        this.loadDataForm();
        

      }else{
        this.errorResponse = true;
      }
      this.loading = false;
    });
  }

  loadDataForm(){
    this.loading = true;
    this.userForm.get('clientName').setValue(this.positionData.name);
    this.loading = false;
  }


 
  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;

      this.userForm.value.id = this.positionData.id;

      console.log("this.userForm.value", this.userForm.value);
      
      this.connectionService
        .insertService(this.userForm.value, "modifyClient.php")
        .subscribe(data => {
          console.log("data modifyLocation is:", data);
          this.loading = false;
          if (!data.error) {
            this.userForm.reset();
            this.sent = true;

            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Se modificó exitosamente"
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/Clientes"]);                 
            });
          } else {
            this.errorResponse = true;
          }
        });

        
    }
  }
}
