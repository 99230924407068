import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';
import { StorageValuesService } from './services/storage-values.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'dotech';
  loginbtn: boolean;
  logoutbtn: boolean;
  mobileNav = false;

  constructor(private dataService: LoginService,
    private store: StorageValuesService) {
    this.dataService.getLoggedInName.subscribe(
      (data: any) => {
        console.log("dataa es", data);
      });

      if (this.store.isLoggedIn('token')) {
        console.log("loggedin en app component");
        this.loginbtn = false;
        this.logoutbtn = true
      }
      else {
        this.loginbtn = true;
        this.logoutbtn = false
      }
  }

  private changeName(token: boolean): void {
    this.logoutbtn = token;
    this.loginbtn = !token;
  }
  
  logout() {
    this.store.deleteStorage('token');
    window.location.href = window.location.href;
  }

  ngOnInit() {

  }

  toggleSideBar() {
    this.mobileNav = !this.mobileNav;
  }

  review(event) {
    console.log("in header review", event);
  }
}
