<div class="container">
    <div class="row ">
        <div class="col-12 col-sm-6">
            <h3>Clientes</h3>
        </div>
        <div class="col-12 col-sm-6">
            <button (click)="addClient()" type="button" class="btn red">Nuevo Cliente</button>
        </div>
    </div>

    <div class="row" [ngClass]="{ 'mt-5': loading || noRows }">
        <div class="col-12">
            <mat-spinner *ngIf="loading"></mat-spinner>
        </div>
        <div class="col-12 text-center" *ngIf="noRows">
            <p>No hay clientes</p>
        </div>
    </div>
    <div class="row" *ngIf="!noRows">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-5" *ngFor="let each of clients">
            <div class="card">
                <div class="card-header">
                    <div class="text-right">
                        <div class="icon-modify" (click)="modifyClientImage(each.id)"><span class="icon-image"></span></div>
                        <div class="icon-modify" (click)="modifyClient(each.id)"><span class="icon-pencil"></span></div>
                        <div class="icon-modify" (click)="removeClient(each.id)"><span class="icon-bin2"></span></div>
                    </div>
                </div>
                <div class="card-body">
                    <p class="text-center">{{each.name}}</p>
                    <img class="mw-100" [src]="each.imageUrl" alt="">
                </div>
            </div>
        </div>
    </div>
</div>