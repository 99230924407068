import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";

import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { MatDialog } from "@angular/material";
import { GeneralModalComponent } from "../../modals/general-modal/general-modal.component";
@Component({
  selector: 'app-modify-tools',
  templateUrl: './modify-tools.component.html',
  styleUrls: ['./modify-tools.component.scss']
})
export class ModifyToolsComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  error: {};
  selectedPositionId: any;

  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  positions: any = [];
  additionalServiceData: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      id: ["", ""],
      table: ["", ""],
      itemName: ["", Validators.required],
      });

    this.loadContent();
    // this.loadFake();
  }

  get f() {
    return this.userForm.controls;
  }

  displayFn(item) {
    if (item) {
      return item.nombre;
    }
  }

  back(){
    this.router.navigate(["/Herramientas"]);
  }


  loadFake(){
    
    this.selectedPositionId = '1';
    this.additionalServiceData = [
      {"id":"1","name":"CONSULTORÍA DE TI"}
      ];
    this.additionalServiceData = this.additionalServiceData[0];
    console.log("data getadditionalServiceData is:", this.additionalServiceData);

    this.loadDataForm();
  }

  loadContent() {
    // this.selectedPositionId = this.store.getSelectedPosition().value;
    this.selectedPositionId = this.store.getStorage("idPosition");
    console.log("this.selectedPositionId", this.selectedPositionId);
    
    
    this.loadAdditionalServiceData();
    
  }

  loadAdditionalServiceData(){
    this.loading = true;
    this.errorResponse = false;
    let obj = {
      idPosition: this.selectedPositionId,
      table: "tools"
    };
    this.connectionService.getService("getItemData.php", obj).subscribe(data => {
      console.log("data getAdditionalServiceData is:", data);
      
      if (!data.error) {
        this.additionalServiceData = data.info[0];
        
        this.loadDataForm();
        

      }else{
        this.errorResponse = true;
      }
      this.loading = false;
    });
  }

  loadDataForm(){
    this.loading = true;
    this.userForm.get('itemName').setValue(this.additionalServiceData.name);
    this.loading = false;
  }
 
  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;

      this.userForm.value.id = this.additionalServiceData.id;
      this.userForm.value.table = "tools";

      console.log("this.userForm.value", this.userForm.value);
      
      this.connectionService
        .insertService(this.userForm.value, "modifyItem.php")
        .subscribe(data => {
          console.log("data modifyTool is:", data);
          this.loading = false;
          if (!data.error) {
            this.userForm.reset();
            this.sent = true;
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Se modificó con éxito"
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/Herramientas"]);
            });
          } else {
            this.errorResponse = true;
          }
        });

        
    }
  }
}

