import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConnectionService } from "src/app/services/connection.service";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
} from "@angular/material";
import { RemoveModalComponent } from '../../modals/remove-modal/remove-modal.component';
import { StorageValuesService } from 'src/app/services/storage-values.service';


@Component({
  selector: "app-admin-positions",
  templateUrl: "./admin-positions.component.html",
  styleUrls: ["./admin-positions.component.scss"],
})
export class AdminPositionsComponent implements OnInit {
  noRows: boolean = false;
  loading: boolean = true;
  positions: any = [];
  displayedColumns: string[] = ["name", "description", "location"];
  dataSource: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private store: StorageValuesService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadContent();
    // this.loadFake();
  }

  loadFake() {
    this.loading = false;
    this.positions = [
      {"id":"6","name":"Frontend Developer","description":"Desarrollador forntend con experiencia en React.","idLocation":"1","locationCity":"MONTERREY, NUEVO LEÓN"},
      {"id":"7","name":"Backend Developer","description":"Desarrollador Backend con experiencia en Node","idLocation":"2","locationCity":"TEPIC, NAYARIT"},
      {"id":"9","name":"Project Manager Sr.","description":"Líder de equipo de desarrollo","idLocation":"1","locationCity":"MONTERREY, NUEVO LEÓN"},
      {"id":"19","name":"Team Leader","description":"Team leader para equipos remotos","idLocation":"3","locationCity":"MÉXICO, D.F."},
      {"id":"20","name":"Team Leader","description":"Team leader en equipos grandes","idLocation":"1","locationCity":"MONTERREY, NUEVO LEÓN"}
  ];
  }



  loadContent() {

    this.loading = true;
    
    this.store.deleteStorage("idPosition");

    let obj = {
      table: "positions"
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getPositions.php", obj)
      .subscribe((data) => {
        console.log("data getPositions is:", data);
        this.loading = false;
        if (!data.error) {
          this.positions = data.info;

          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addPosition() {
    this.router.navigate(["/AgregarVacante"]);
  }

 

  removeItem(idPosition): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      width: '500px',
      data: {idPosition: idPosition, table: 'positions', title: 'Vacante'},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.status){
        this.loadContent();
      }
      
    });
  }

  modifyPosition(idPosition) {
    console.log("idPosition in modifyPosition", idPosition);
    // this.store.setSelectedPosition(idPosition);
    this.store.setStorage("idPosition",idPosition);
    this.router.navigate(["/ModificarVacante"]);
  }
}
