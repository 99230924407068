import {
  Component,
  OnInit,
  HostBinding,
  Input,
  Output,
  EventEmitter,
  HostListener
} from "@angular/core";
import { LoginService } from "src/app/services/login.service";
import { StorageValuesService } from "src/app/services/storage-values.service";
@Component({
  selector: 'app-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.scss']
})
export class AdminNavigationComponent implements OnInit {
  loginbtn: boolean;
  logoutbtn: boolean;

  constructor(private dataService: LoginService,
    private store: StorageValuesService) {

    this.dataService.getLoggedInName.subscribe(
      (data: any) => {
        console.log("dataa es", data);
      });

    if (this.store.isLoggedIn('token')) {
      console.log("loggedin!! wuwuwuw");
      this.loginbtn = false;
      this.logoutbtn = true
    }
    else {
      this.loginbtn = true;
      this.logoutbtn = false
    }
  }

  ngOnInit() {}

  private changeName(token: boolean): void {
    this.logoutbtn = token;
    this.loginbtn = !token;
  }
  
  logout() {
    this.store.deleteStorage('token');
    window.location.href = window.location.href;
  }

  @Output() toggle: EventEmitter<any> = new EventEmitter();

  @HostListener("click")
  click() {
    this.toggle.emit();
  }

  @HostBinding("class.is-open")
  @Input()
  isOpen = false;

}
