<div class="container">
    <div class="row ">
        <div class="col-12 col-sm-6">
            <h3>Vacantes Disponibles</h3>
        </div>
        <div class="col-12 col-sm-6">
            <button (click)="addPosition()" type="button" class="btn red">Nueva Vacante</button>
        </div>
    </div>

    <div class="row" [ngClass]="{ 'mt-5': loading || noRows }">
        <div class="col-12">
            <mat-spinner *ngIf="loading"></mat-spinner>
        </div>
        <div class="col-12 text-center" *ngIf="noRows">
            <p>No hay vacantes</p>
        </div>
    </div>
    <div class="row" *ngIf="!noRows">
        <div class="col-12 col-sm-6 col-md-4 mt-5" *ngFor="let each of positions">
            <div class="card">
                <div class="card-header">
                    <div class="text-right">
                        <div class="icon-modify" (click)="modifyPosition(each.id)"><span class="icon-pencil"></span></div>
                        <div class="icon-modify" (click)="removeItem(each.id)"><span class="icon-bin2"></span></div>
                    </div>
                </div>
                <div class="card-body">
                    <h5 class="card-title">{{ each.name }}</h5>
                    <hr />
                    <div class="mt-4">
                        <h6 class="card-subtitle text-muted">Descripción</h6>
                        <p class="card-text">{{ each.description }}</p>
                    </div>
                    <div class="mt-4">
                        <h6 class="card-subtitle text-muted">Ubicación</h6>
                        <p class="card-text">{{ each.city }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>