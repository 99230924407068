<section>
    <div>
        <div class="container">
            <div class="loading" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <h2 class="subhead-title">Agregar Tecnología</h2>
                </div>
            </div>
            <div class="row">
                <div class="offset-lg-2 col-lg-8 offset-md-1 col-md-10">

                    <div class="alert alert-danger" role="alert" *ngIf="errorResponse">
                        Hubo un problema, intentalo más tarde.
                    </div>
                    <form class="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()" *ngIf="!sent">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="techName">Nombre de Tecnología</label>
                                    <input formControlName="techName" type="text" class="form-control" id="techName" required />

                                    <div *ngIf="submitted && f.techName.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.techName.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-12">
                                <div class="form-group">
                                    <label for="techIcon">Icono</label>
                                    <div>

                                        <mat-radio-group formControlName="techIcon" aria-label="Select an option" required>
                                            <mat-radio-button class="services-radio d-inline-block" [value]="each.id" *ngFor="let each of icons">
                                                <span class="service-icon" [ngClass]="each.id"></span>
                                            </mat-radio-button>
                                        </mat-radio-group>

                                    </div>
                                    <div *ngIf="submitted && f.techIcon.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.techIcon.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="col-12">
                                <button type="submit" class="btn red mt-3">Seleccionar Herramientas</button>
                                <button class="btn m-auto red-outline" (click)="back()">Cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>