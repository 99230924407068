

import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";

import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { GeneralModalComponent } from '../../modals/general-modal/general-modal.component';
import { MatDialog } from '@angular/material';
import { FullProcessService } from 'src/app/services/full-process.service';
@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  successSubmition: boolean = false;
  error: {};
  
  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  
  positions: any = [];
  

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private process: FullProcessService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      locationCity: ["", Validators.required],
      locationBuilding: [""],
      locationAddress: ["", Validators.required],
      locationMap: ["", Validators.required]
      
      });
  
  }

  back(){
    this.router.navigate(["/Ubicaciones"]);  
  }


  get f() {
    return this.userForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;
      
      
      this.connectionService
        .insertService(this.userForm.value, "addLocation.php")
        .subscribe(data => {
          console.log("data addLocation is:", data);
          this.loading = false;
          if (!data.error) {
            this.userForm.reset();
            this.sent = true;
            
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Se agregó exitosamente"
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/Ubicaciones"]);             
            });

          } else {
            this.errorResponse = true;
          }
        });

        
    }
  }
}
