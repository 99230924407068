import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";

import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { MatDialog } from "@angular/material";
import { GeneralModalComponent } from "../../modals/general-modal/general-modal.component";
import { CvUploadService } from "src/app/services/cv-upload.service";

@Component({
  selector: 'app-modify-clients-img',
  templateUrl: './modify-clients-img.component.html',
  styleUrls: ['./modify-clients-img.component.scss']
})
export class ModifyClientsImgComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  error: {};
  selectedPositionId: any;
  uploadResponse;
  errorSubmition: boolean = false;
  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  positions: any = [];
  positionData: any = [];

  constructor(
    private cvUpload: CvUploadService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      id: [""],
      userUpload: ["", Validators.required],
      hLImage: [""],  
      });

    this.loadContent();
    // this.loadFake();
  }

  back(){
    this.router.navigate(["/Clientes"]);
  }


  get f() {
    return this.userForm.controls;
  }

  displayFn(item) {
    if (item) {
      return item.nombre;
    }
  }




  

  loadContent() {
    // this.selectedPositionId = this.store.getSelectedPosition().value;
    this.selectedPositionId = this.store.getStorage("idPosition");
    console.log("this.selectedPositionId", this.selectedPositionId);
 
  }



  loadDataForm(){
    this.loading = true;
    
    this.userForm.get('id').setValue(this.positionData.id);
    
    this.loading = false;
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userForm.get("userUpload").setValue(file);
    }
  }


 
  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;


      console.log("this.userForm.value", this.userForm.value);

        //first upload CV
        const formData = new FormData();
        formData.append("userUpload", this.userForm.get("userUpload").value);
  
        console.log("formData", formData);
  
        return this.cvUpload.uploadFile(formData).subscribe(
          (res) => {
            this.uploadResponse = res;
            console.log("result is", res);

  
            //If Cv was uploaded, then send information about candidate
            if (!res.error) {
              this.userForm.get("hLImage").setValue(res.url);
              this.userForm.get("id").setValue(this.selectedPositionId);
              console.log("FORM TO GO", this.userForm.value);
  
              this.connectionService
                .insertService(this.userForm.value, "modifyClientImage.php")
                .subscribe((data) => {
                  
                    if (!data.error) {
                    this.userForm.reset();
                    this.loading = false;
                    
                    const dialogRef = this.dialog.open(GeneralModalComponent, {
                      width: '500px',
                      data: "Se modificó exitosamente"
                    });
  
                    dialogRef.afterClosed().subscribe(result => {
                      this.router.navigate(["/Clientes"]);                 
                    });
                    
                  }else{
                    this.loading = false;
                    this.errorSubmition = true;
                  }
                });
            }else{
              this.loading = false;
              this.errorSubmition = true;
            }
  
          },
          (err) => {
            this.loading = false;
            console.log("error is", err);
          }
        );
      
     

        
    }
  }
}
