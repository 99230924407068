<div class="container">
    <div class="row">
        <div class="col-12 col-sm-6">
            <h3>Listado de Herramientas en Tecnologías</h3>
        </div>
        <div class="col-12 col-sm-6">
            <button (click)="addNew()" type="button" class="btn red">Agregar Herramienta para Tecnología</button>
        </div>
    </div>
    <div class="row">

        <div class="col-12 text-center mt-5" *ngIf="noRows">
            <p>No hay Herramientas</p>
        </div>
        <div class="col-12 mt-5">
            <div class="form-group">
                <input class="form-control" (keyup)="applyFilter($event)" placeholder="Búsqueda" />
            </div>

            <div class="">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Herramienta
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let row">
                            <button class="btn red-outline small-auto" (click)="modifyTool(row.id)"><span class="icon-pencil"></span></button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="remove">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let row">
                            <button class="btn red-outline small-auto" (click)="removeTool(row.id)"><span class="icon-bin2"></span></button>
                        </td>
                    </ng-container>




                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>

    </div>
</div>