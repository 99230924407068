<div class="container">
    <div class="row">
        <div class="col-12">
            <h3>Listado de Contactos</h3>
        </div>
        <div class="col-12 text-center mt-5" *ngIf="noRows">
            <p>No hay contactos</p>
        </div>
        <div class="col-12 mt-5">
            <div class="form-group">
                <input class="form-control" (keyup)="applyFilter($event)" placeholder="Búsqueda" />
            </div>

            <div class="">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Nombre
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Teléfono</th>
                        <td mat-cell *matCellDef="let row">{{ row.phone }}</td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
                        <td mat-cell *matCellDef="let row">{{ row.company }}</td>
                    </ng-container>
                    <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mensaje</th>
                        <td mat-cell *matCellDef="let row">{{ row.message }}</td>
                    </ng-container>
                    <ng-container matColumnDef="dateContact">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
                        <td mat-cell *matCellDef="let row">{{ row.dateContact }}</td>
                    </ng-container>
                    <ng-container matColumnDef="remove">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let row">
                            <button class="btn red-outline small-auto" (click)="removeService(row.id)"><span class="icon-bin2"></span></button>
                        </td>
                    </ng-container>



                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>

    </div>
</div>