

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from "@angular/material";
import { ConnectionService } from "src/app/services/connection.service";
import { Router } from "@angular/router";
import { RemoveModalComponent } from "../../modals/remove-modal/remove-modal.component";

@Component({
  selector: 'app-admin-candidates',
  templateUrl: './admin-candidates.component.html',
  styleUrls: ['./admin-candidates.component.scss']
})
export class AdminCandidatesComponent implements OnInit {
  noRows: boolean = false;
  loading: boolean = true;

  contactos: any = [];
  contactos2: any = [
    {
      id: "1",
      name: "Julia Ramos",
      cvUrl: "http://vacantes.dotechmx.com/backend/services/uploads/945748-carta2.pdf",
      phone: "81297127128",
      email: "rms.julle@gmail.com",
      info: "Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?Hola?",
      position: "Frontend Developer",
      dateContact: "September 25, 2020, 11:51 pm"
    },
    {
      id: "2",
      name: "Raul Gomez",
      cvUrl: "http://vacantes.dotechmx.com/backend/services/uploads/945748-carta2.pdf",
      phone: "182712871289",
      email: "raul.gomez.mont@gmail.com",
      info: "Test text",
      position: "Backend Developer",
      dateContact: "September 25, 2020, 11:51 pm"
    },
  ];
  displayedColumns: string[] = ["name", "phone", "email", "info", "position","dateContact","cvUrl","remove"];
  dataSource: any;

  //dataSource = new MatTableDataSource(this.contactos);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadContent();
    // this.loadFake();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadFake() {
    this.loading = false;
   
    this.dataSource = new MatTableDataSource(this.contactos2);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.noRows = false;
  }

  loadContent() {
    let obj = {
      table: "candidates"
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data getCandidates is:", data);
        this.loading = false;
        if (!data.error) {
          this.contactos = data.info;
          this.dataSource = new MatTableDataSource(this.contactos);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.noRows = false;
        } else{
          this.noRows = true;
        }
      });
  }

  goDetails(detail) {
    console.log("detail is", detail);
    // this.router.navigate(['/MedicamentoEspecifico']);
  }

  removeService(idPosition): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      width: '500px',
      data: {idPosition: idPosition, table: 'candidates', title:'Candidatos'},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.status){
        this.loadContent();
      }
      
    });
  }
}
