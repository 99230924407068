

import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";

import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { GeneralModalComponent } from '../../modals/general-modal/general-modal.component';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-add-service-extra',
  templateUrl: './add-service-extra.component.html',
  styleUrls: ['./add-service-extra.component.scss']
})
export class AddServiceExtraComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  successSubmition: boolean = false;
  error: {};
  
  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  
  positions: any = [];
  

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      table: ["", ""],
      itemName: ["", Validators.required],
      
      });
  
  }

  back(){
    this.router.navigate(["/ServiciosAdicionales"]);
  }

  get f() {
    return this.userForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;
      
      this.userForm.value.table = "additionalServices";
      
      this.connectionService
        .insertService(this.userForm.value, "addItem.php")
        .subscribe(data => {
          console.log("data addAdditionalService is:", data);
          this.loading = false;
          if (!data.error) {
            this.userForm.reset();
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Se agregó exitosamente"
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/ServiciosAdicionales"]);
            });
          } else {
            this.errorResponse = true;
          }
        });

        
    }
  }
}
