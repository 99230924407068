import { Component, OnInit, Output, EventEmitter, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConnectionService } from "src/app/services/connection.service";
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { FullProcessService } from 'src/app/services/full-process.service';
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { first } from 'rxjs/operators';
import * as cryptoJS from 'crypto-js';
import { LoginService } from "src/app/services/login.service";
import { MatDialog } from "@angular/material";
import { GeneralModalComponent } from "../modals/general-modal/general-modal.component";


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  loginForm: FormGroup;
  submitted: boolean;
  successSubmition: boolean = false;
  errorSubmition: boolean = false;
  error: {};
  username = "";
  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  
  positions: any = [];
  emailPattern = "^[a-z0-9._%+-]{2,}@[a-z0-9.-]{2,}\.[a-z]{2,}$";

  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private dataService: LoginService,
    private connectionService: ConnectionService,
    private process: FullProcessService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=.*[$@$\\_\\-\\#!%*?&])(?=[^A-Z]*[A-Z]).{8,30}')
         ]
      ]
    });
  }

  ngOnInit() {
    this.username = this.store.getStorage('user');
    console.log("username", this.username);
    this.loginForm.get('email').setValue(this.username);

  }

  
  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }


  get f() {
    return this.loginForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;
    console.log("this.loginForm", this.loginForm);

    if (this.loginForm.invalid) {
      console.log("invalido");
      return;
    } else {
      this.loading = true;
     
      console.log("inside submit");

      this.dataService
        .userlogin(this.loginForm.value, "changePassword.php")
        .subscribe(data => {
          
          this.loading = false;
          
          if (!data.error) {
            this.loginForm.reset();
            this.loading = false;
            
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Se modificó exitosamente"
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/Inicio"]);                 
            });
            
          }else{
            this.loading = false;
            this.errorSubmition = true;
          }
        });


    }
  }






}
