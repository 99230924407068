<section>
    <div>
        <div class="container">
            <div class="loading" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <h2 class="subhead-title">Modificar Servicio</h2>
                </div>
            </div>
            <div class="row">
                <div class="offset-lg-2 col-lg-8 offset-md-1 col-md-10">
                    <div class="alert alert-success" role="alert" *ngIf="sent">
                        Se modificó servicio exitosamente
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="errorResponse">
                        Hubo un problema, intentalo más tarde.
                    </div>
                    <form class="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()" *ngIf="!sent">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="serviceName">Nombre de Servicio</label>
                                    <input formControlName="serviceName" type="text" class="form-control" id="serviceName" required />

                                    <div *ngIf="submitted && f.serviceName.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.serviceName.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label for="serviceDesc">Descripción</label>
                                    <textarea formControlName="serviceDesc" type="text" class="form-control" id="serviceDesc" required></textarea>

                                    <div *ngIf="submitted && f.serviceDesc.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.serviceDesc.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label for="serviceIcon">Icono</label>
                                    <div>

                                        <mat-radio-group formControlName="serviceIcon" aria-label="Select an option" required>
                                            <mat-radio-button class="services-radio d-inline-block" [value]="each.id" [checked]="each.id == positionData.icon" *ngFor="let each of icons">
                                                <span class="service-icon" [ngClass]="each.id"></span>
                                            </mat-radio-button>
                                        </mat-radio-group>

                                    </div>
                                    <div *ngIf="submitted && f.serviceIcon.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.serviceIcon.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="btn red mt-3">Modificar</button>
                                <button class="btn m-auto red-outline" (click)="back()">Cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>