<div class="container">
    <div class="row ">
        <div class="col-12 col-sm-6">
            <h3>Tecnologías</h3>
        </div>
        <div class="col-12 col-sm-6">
            <button (click)="addTechnology()" type="button" class="btn red">Nueva Tecnología</button>
        </div>
    </div>

    <div class="row" [ngClass]="{ 'mt-5': loading || noRows }">
        <div class="col-12">
            <mat-spinner *ngIf="loading"></mat-spinner>
        </div>
        <div class="col-12 text-center" *ngIf="noRows">
            <p>No hay tecnologías</p>
        </div>
    </div>
    <div class="row" *ngIf="!noRows">
        <div class="col-12 col-sm-6 col-md-4 mt-5" *ngFor="let each of technologies">
            <div class="card">
                <div class="card-header">
                    <div class="text-right">
                        <div class="icon-modify" (click)="modifyTech(each.id)"><span class="icon-pencil"></span></div>
                        <div class="icon-modify" (click)="removeTech(each.id)"><span class="icon-bin2"></span></div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="mt-4">
                        <div class="service-icon" [ngClass]="each.icon"></div>
                    </div>
                    <h5 class="card-title text-center">{{ each.name }}</h5>

                </div>
            </div>
        </div>
    </div>
</div>