

import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { throwError, Subject, BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import * as cryptoJS from 'crypto-js';

@Injectable({
  providedIn: "root"
})
export class StorageValuesService {
  private selectedPosition = new BehaviorSubject(null);

  constructor() {}

  encrypt(value : string) : string{
    return cryptoJS.AES.encrypt(value, "").toString();
  }

  decrypt(textToDecrypt : string){
    return cryptoJS.AES.decrypt(textToDecrypt, "").toString(cryptoJS.enc.Utf8);
  }

  setSelectedPosition(selectedPosition: any) {
    this.selectedPosition.next(selectedPosition);
  }

  getSelectedPosition() {
    return this.selectedPosition;
  }

  setStorage(nameStorage:string, token: string) {
    localStorage.setItem(nameStorage, token);
  }
  getStorage(nameStorage:string) {
    return localStorage.getItem(nameStorage);
  }
  deleteStorage(nameStorage:string) {
    localStorage.removeItem(nameStorage);
  }
  isLoggedIn(nameStorage:string) {
    // const usertoken = this.decrypt(this.getStorage(nameStorage));
    // const username = this.getStorage('username');

    // if (usertoken === username) {
    //   return true;
    // }

    const usertoken = this.getStorage(nameStorage);
    if (usertoken != null) {
      return true;
    }
    return false;
  }

}
