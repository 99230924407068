<section class="s-login">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center mt-30">
                <img class="logo mb-20" src="/assets/images/logo dotech.png" alt="Dotech" />
            </div>
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div class="card card-signin my-5">
                    <div class="card-body">
                        <h5 class="card-title text-center">Inicio Sesión</h5>
                        <div class="alert alert-danger" role="alert" *ngIf="errorResponse">
                            Usuario o Contraseña incorrecto.
                        </div>
                        <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="email">Usuario</label>
                                        <input formControlName="email" type="text" class="form-control" id="email" required />

                                        <div *ngIf="submitted && f.email.errors" class="d-block invalid-feedback">
                                            <div *ngIf="f.email.errors.required">
                                                *Campo Requerido
                                            </div>
                                        </div>
                                        <div *ngIf="f.email.touched && f.email.errors?.pattern" class="d-block invalid-feedback">
                                            *Formato Inválido. Ej: hola@correo.com
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="password">Contraseña</label>
                                        <input formControlName="password" type="password" class="form-control" id="password" required />

                                        <div *ngIf="submitted && f.password.errors" class="d-block invalid-feedback">
                                            <div *ngIf="f.password.errors.required">
                                                *Campo Requerido
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-12">
                                    <button type="submit" class="btn red mt-3">Iniciar Sesión</button>
                                </div>
                            </div>
                        </form>

                        <!-- <form class="form-signin" id="loginForm" name="loginForm" novalidate>
                            <div class="form-label-group">
                                <input type="email" id="usuario" name="usuario" class="form-control" placeholder="Usuario" ng-model="returnData.usuario" autocomplete="off" required />
                                <span class="text-danger invalid-message d-block" ng-show="loginForm.usuario.$error.required && loginForm.usuario.$touched" ng-cloak>*Campo obligatorio</span
                >
              </div>

              <div class="form-label-group">
                <input
                  type="password"
                  id="password"
                  name="password"
                  class="form-control"
                  placeholder="Password"
                  ng-model="returnData.password"
                  autocomplete="off"
                  required
                />
                <span
                  class="text-danger invalid-message d-block"
                  ng-show="loginForm.password.$error.required && loginForm.password.$touched"
                  ng-cloak
                  >*Campo obligatorio</span
                >
              </div>

              <a
                href="/views/obras.html"
                class=" btn red text-uppercase"
                >Ingresar</a
              >
            </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>