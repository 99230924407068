import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConnectionService } from "src/app/services/connection.service";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
} from "@angular/material";
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { RemoveModalComponent } from '../modals/remove-modal/remove-modal.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  noRows: boolean = false;
  loading: boolean = true;
  clients: any = [];
  dataSource: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private store: StorageValuesService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadContent();
    // this.loadFake();

  }

  loadFake() {
    this.loading = false;
    this.clients = [
      {
        "id": "1",
        "name": "OXXO",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/oxxo.png"
    },
    {
        "id": "2",
        "name": "ARH",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/arh.png"
    },
    {
        "id": "3",
        "name": "MAS",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/mas.png"
    },
    {
        "id": "4",
        "name": "EATON",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/eaton.png"
    },
    {
        "id": "5",
        "name": "PETRO 7",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/petro.png"
    },
    {
        "id": "6",
        "name": "FARMACIAS DEL AHORRO",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/farmacias.png"
    },
    {
        "id": "7",
        "name": "FULTRA",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/fultra.png"
    },
    {
        "id": "8",
        "name": "7 ELEVEN",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/seven.png"
    },
    {
        "id": "9",
        "name": "DALTILE",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/daltile.png"
    },
    {
        "id": "10",
        "name": "GUNTNER",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/gunter.png"
    },
    {
        "id": "11",
        "name": "GRUPO PROMAX",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/promax.png"
    },
    {
        "id": "12",
        "name": "DE ACERO",
        "imageUrl": "http://www.dotechmx.com/assets/images/clients/deacero.png"
    }


    ];
  }


  loadContent() {

    this.store.deleteStorage("idPosition");
    
    let obj = {
      table: "clients"
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data getClientes is:", data);
        this.loading = false;
        if (!data.error) {
          this.clients = data.info;
          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }

  addClient() {
    this.router.navigate(["/AgregarCliente"]);
  }

  modifyClient(idPosition) {
    console.log("idPosition in modifyClient", idPosition);
    // this.store.setSelectedPosition(idPosition);
    this.store.setStorage("idPosition",idPosition);
    this.router.navigate(["/ModificarCliente"]);
  }

  modifyClientImage(idPosition) {
    console.log("idPosition in modifyClient", idPosition);
    // this.store.setSelectedPosition(idPosition);
    this.store.setStorage("idPosition",idPosition);
    this.router.navigate(["/ModificarClienteLogo"]);
  }



  removeClient(idPosition): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      width: '500px',
      data: { idPosition: idPosition, table: 'clients', title: 'Cliente' },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.status) {
        this.loadContent();
      }

    });
  }


}
