<section>
    <div>
        <div class="container">
            <div class="loading" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <h2 class="subhead-title">Cambiar contraseña</h2>
                </div>
            </div>
            <div class="row">
                <div class="offset-lg-2 col-lg-8 offset-md-1 col-md-10">

                    <div class="alert alert-warning" role="alert" *ngIf="errorSubmition">
                        Hubo un error, porfavor intenta más tarde.
                    </div>
                    <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
                        <div class="row">

                            <div class="col-12">
                                <div class="form-group">
                                    <label for="password">Nueva Contraseña</label>
                                    <input formControlName="password" type="password" class="form-control" id="password" required />
                                    <div *ngIf="submitted && f.password.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.password.errors.required">
                                            *Campo Requerido
                                        </div>
                                        <div *ngIf="f.password.errors.pattern">
                                            *Formato Inválido
                                        </div>
                                    </div>
                                    <ng-container>

                                        <ul class="mt-3 text-secondary">
                                            <li>Mínimo 8 caractéres</li>
                                            <li>Por lo menos 1 mayúscula</li>
                                            <li>Incluir minúsculas</li>
                                            <li>Por lo menos 1 número</li>
                                            <li>Por lo menos 1 caracter especial</li>
                                        </ul>
                                    </ng-container>


                                </div>
                            </div>


                            <div class="col-12">
                                <button type="submit" class="btn red mt-3">Confirmar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>