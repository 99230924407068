<div class="container">
    <div class="row">
        <div class="col-12">
            <h3 mat-dialog-title>Eliminar {{data.title}}</h3>
        </div>

        <div class="col-12">

            <div class="alert alert-danger" role="alert" *ngIf="errorResponse">
                Hubo un problema, intentalo más tarde.
            </div>
            <div mat-dialog-content>
                <form class="userForm" [formGroup]="userForm" (ngSubmit)="removeItem()">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="confirmacion">Escribe la palabra "<strong>ELIMINAR</strong>" en Mayúsculas
                </label>
                                <input formControlName="confirmacion" type="text" class="form-control" id="confirmacion" autocomplete="off" required />
                                <div *ngIf="submitted && f.confirmacion.errors" class="d-block invalid-feedback">
                                    <div *ngIf="f.confirmacion.errors.required">
                                        *Campo Requerido
                                    </div>
                                </div>
                                <div *ngIf="submitted && notConfirmed" class="d-block invalid-feedback">
                                    <div>
                                        *Error en Respuesta
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div>
                                <button class="btn red-outline" (click)="close(false)">Cancelar</button>
                                <button class="btn red" type="submit">
                  CONFIRMAR
                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>