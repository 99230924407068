import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConnectionService } from "src/app/services/connection.service";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
} from "@angular/material";
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { RemoveModalComponent } from '../modals/remove-modal/remove-modal.component';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  noRows: boolean = false;
  loading: boolean = true;
  services: any = [];
  dataSource: any;
  addServicesFlag:boolean= false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private store: StorageValuesService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadContent();
    // this.loadFake();
    
  }

  loadFake() {
    this.loading = false;
    this.services = [
      {"id":"1","name":"Software a la medida","description":"Nos enfocamos en las necesidades específicas del cliente, adaptándonos a los procesos de gestión.","icon":"icon-embed2"},
      {"id":"2","name":"Outsourcing de personal","description":"Nos acoplamos a la forma de trabajo colaborativo, adaptándonos a cualquier cambio con mayor rapidez.","icon":"icon-users"}      
      ];

      this.loadAddFlag();
     
  }

  loadAddFlag(){
    console.log("this.services.length",this.services.length);
    if(this.services.length < 3){
      this.addServicesFlag = true;
    }
  }



  loadContent() {
    this.loading = true;
    this.store.deleteStorage("idPosition");

    let obj = {
      table: "services"
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data getServices is:", data);
        this.loading = false;
        if (!data.error) {
          this.services = data.info;
          this.noRows = false;
          this.loadAddFlag();
        } else {
          this.noRows = true;
        }
      });
  }

  addService() {
    this.router.navigate(["/AgregarServicio"]);
  }

 

  removeService(idPosition): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      width: '500px',
      data: {idPosition: idPosition, table: 'services', title: 'Servicio'},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.status){
        this.loadContent();
      }
      
    });
  }

  modifyService(idPosition) {
    console.log("idPosition in modifyService", idPosition);
    // this.store.setSelectedPosition(idPosition);
    this.store.setStorage("idPosition",idPosition);
    this.router.navigate(["/ModificarServicio"]);
  }
}
