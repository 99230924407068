<section>
    <div>
        <div class="container">
            <div class="loading" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <h2 class="subhead-title">Agregar Herramientas en Tecnología</h2>
                </div>
            </div>
            <div class="row">
                <div class="offset-lg-2 col-lg-8 offset-md-1 col-md-10">

                    <div class="alert alert-danger" role="alert" *ngIf="errorResponse">
                        Hubo un problema, intentalo más tarde.
                    </div>
                    <form class="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()" *ngIf="!sent">
                        <div class="row">

                            <div class="col-12">
                                <div class="form-group">
                                    <label for="techIcon">Selecciona las herramientas que corresponden a la tecnología</label>
                                    <div>
                                        <mat-selection-list formControlName="techTools">
                                            <mat-list-option class="option-list" [value]="tool.id" [selected]="tool.selected" *ngFor="let tool of toolsData">
                                                {{tool.name}}
                                            </mat-list-option>
                                        </mat-selection-list>
                                    </div>
                                    <div *ngIf="submitted && f.techTools.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.techTools.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="btn red mt-3">Agregar</button>
                                <button class="btn m-auto red-outline" (click)="back()">Cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>