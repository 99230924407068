
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";

import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { MatDialog } from "@angular/material";
import { GeneralModalComponent } from "../../modals/general-modal/general-modal.component";
@Component({
  selector: 'app-modify-position',
  templateUrl: './modify-position.component.html',
  styleUrls: ['./modify-position.component.scss']
})
export class ModifyPositionComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  error: {};
  selectedPositionId: any;
  locations: any = [];
  positionLocations: any = [];
  selectedLocations: any = [];
  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  positions: any = [];
  positionData: any = [];
  noRows: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      id: ["", ""],
      positionName: ["", Validators.required],
      positionDesc: ["", Validators.required],
      positionLocation: ["", Validators.required]
    });

    this.loadContent();
    // this.loadFake();
  }

  back(){
    this.router.navigate(["/Vacantes"]);
  }

  get f() {
    return this.userForm.controls;
  }

  displayFn(item) {
    if (item) {
      return item.nombre;
    }
  }

  loadLocations() {

    let obj = {
      table: "locations"
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data getLocations is:", data);
        this.loading = false;
        if (!data.error) {
          this.locations = data.info;

          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }



  loadFake() {

    this.selectedPositionId = '2';
    this.positionData = [
      { "id": "2", "name": "Backend Developer", "description": "Desarrollador para el área de backend developer. C#", "idLocation":"1" }
    ];
    this.positionData = this.positionData[0];
    console.log("data getpositionData is:", this.positionData);
   
    this.locations = [
      { "id": "1", "city": "MONTERREY, NUEVO LEÓN", "building": "Torre Avalanz - Piso 17 y 18", "address": "Av. Batallón de San Patricio #109 Sur, Col. Valle Oriente, San Pedro Garza García, N.L.", "mapUrl": "https:\/\/goo.gl\/maps\/dj9nh1zdxu9Bz4zM8" },
      { "id": "2", "city": "TEPIC, NAYARIT", "building": "", "address": "San Luis #297 Norte, Col. Centro, Tepic, Nayarit.", "mapUrl": "https:\/\/goo.gl\/maps\/U262GxEtTPZp5HHeA" },
      { "id": "3", "city": "MÉXICO, D.F.", "building": "Plaza Carso", "address": "Calle Lago Zurich #219, Col. Ampliación Granada, Del. Miguel Hidalgo, México, D.F.", "mapUrl": "https:\/\/goo.gl\/maps\/WBhywTv5GBx5CSQGA" }
    ];

    


    this.loadDataForm();

  }

  loadContent() {
    // this.selectedPositionId = this.store.getSelectedPosition().value;
    this.selectedPositionId = this.store.getStorage("idPosition");
    console.log("this.selectedPositionId", this.selectedPositionId);

    this.loadLocations();
    this.loadPositionData();

  }



  loadPositionData() {
    this.loading = true;
    this.errorResponse = false;
    let obj = {
      idPosition: this.selectedPositionId,
      table: "positions"
    };
    this.connectionService.getService("getItemData.php", obj).subscribe(data => {
      console.log("data getPositionData is:", data);

      if (!data.error) {
        this.positionData = data.info[0];
        this.errorResponse = false;
        this.loadDataForm();


      } else {
        this.errorResponse = true;
      }
      this.loading = false;
    });
  }

  loadDataForm() {
    this.loading = true;

    this.userForm.get('positionName').setValue(this.positionData.name);
    this.userForm.get('positionDesc').setValue(this.positionData.description);
    this.userForm.get('positionLocation').setValue(this.positionData.idLocation);

    this.loading = false;
  }



  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;

      this.userForm.value.id = this.positionData.id;

      console.log("this.userForm.value", this.userForm.value);

      this.connectionService
        .insertService(this.userForm.value, "modifyPosition.php")
        .subscribe(data => {
          console.log("data modifyposition is:", data);
          this.loading = false;
          if (!data.error) {
            this.userForm.reset();
            this.sent = true;
            
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Se modificó con éxito"
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/Vacantes"]);
            });
          } else {
            this.errorResponse = true;
          }
        });


    }
  }
}
