import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolsComponent } from './tools.component';
import {
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTableModule,
  MatInputModule,
  MatFormFieldModule,  
} from "@angular/material";
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModifyToolsComponent } from './modify-tools/modify-tools.component';
import { AddToolItemComponent } from './add-tool-item/add-tool-item.component';

@NgModule({
  declarations: [ToolsComponent, ModifyToolsComponent, AddToolItemComponent],
  exports: [ToolsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    RouterModule,
    MatDialogModule,
    MatTableModule,
  ]
})
export class ToolsModule { }
