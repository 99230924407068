import { Injectable, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Users } from '../login/users';
import { catchError } from 'rxjs/operators';
import { throwError,Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class LoginService {
  redirectUrl: string;
  ServerUrl = location.origin + '/backend/services/';
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  errorData: {};

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private httpClient: HttpClient) { }


  userlogin(formdata: any, url:any) {
    return this.httpClient.post<any>(this.ServerUrl + url, formdata, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  
  // public userlogin(formdata: any) {
  //   return this.httpClient.post<any>(this.ServerUrl + 'login.php', formdata, this.httpOptions)
  //     .pipe(map(Users => {
  //       this.setToken(Users[0].token);
  //       this.getLoggedInName.emit(true);
  //       return Users;
  //     }),catchError(this.handleError));
  // }

  public userregistration(email, pwd, token) {
    return this.httpClient.post<any>(this.ServerUrl + 'register.php', { email, pwd, token })
      .pipe(map(Users => {
        return Users;
      }));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong.

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

  

}