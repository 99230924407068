

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConnectionService } from "src/app/services/connection.service";

@Component({
  selector: 'app-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss']
})
export class RemoveModalComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  removed: boolean = false;
  notConfirmed:boolean= false;
  errorResponse: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RemoveModalComponent>,
    private formBuilder: FormBuilder,
    private connectionService: ConnectionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      confirmacion: ["", Validators.required]
    });

    console.log("data received", this.data);
    
  }

  close(status) {
    this.dialogRef.close({'status':status});
  }

  get f() {
    return this.userForm.controls;
  }

  removeItem() {
    this.submitted = true;
    console.log("here");

    if (this.userForm.invalid) {
      return;
    } else {
      let confirmar = this.userForm.value.confirmacion;
      console.log("this.userForm.value.confirmacion", confirmar);
      if (confirmar == "ELIMINAR") {
        console.log("se elimina!!", this.data.idPosition);
        this.remove();
      }else{
        this.notConfirmed = true;
      }
    }
  }

  remove() {
    this.errorResponse = false;

    let obj = {
      idPosition: this.data.idPosition,
      table: this.data.table
    };
    
    this.connectionService
      .getService("removeItem.php", obj)
      .subscribe(data => {
        console.log("data removePosition is:", data);

        if (!data.error) {
          this.removed = true;
          this.close(true);
        } else {
          this.close(false);
          this.errorResponse = true;
        }
      });
    
  }
}
