

import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";
import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import * as LabelsField from "../../../../assets/labels/labels.json";
import find from "lodash-es/find";
import { MatDialog } from "@angular/material";
import { GeneralModalComponent } from "../../modals/general-modal/general-modal.component";

@Component({
  selector: 'app-modify-tech',
  templateUrl: './modify-tech.component.html',
  styleUrls: ['./modify-tech.component.scss']
})
export class ModifyTechComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  error: {};
  selectedPositionId: any;

  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  positions: any = [];
  positionData: any = [];
  toolsData: any = [];
  toolsEachData: any = [];

  icons: any;

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.icons = (<any>LabelsField).icons;
    this.userForm = this.formBuilder.group({
      id: ["", ""],
      techName: ["", Validators.required],
      techIcon: ["", Validators.required],
      techTools:["", Validators.required]
      });

    this.loadContent();
    // this.loadFake();
  }

  back(){
    this.router.navigate(["/Tecnologias"]);
  }


  get f() {
    return this.userForm.controls;
  }

  displayFn(item) {
    if (item) {
      return item.nombre;
    }
  }




  loadFake(){

    console.log("LOADING FAKE --------");
    
    this.selectedPositionId = '2';
    
    this.positionData = [
      {
         "id":1,
         "name":"Oracle Retail",
         "icon":"icon-oracle"
      }
    ];

    this.toolsData = [
      {"id":"1","name":"Access"},
      {"id":"2","name":"ALM-HP"},
      {"id":"3","name":"App Service"},
      {"id":"4","name":"Artus"},
      {"id":"5","name":"ASP\/PHP"},
      {"id":"6","name":"Atlassian Jira"},
      {"id":"7","name":"Automation"},
      {"id":"8","name":"Business Objects"},
      {"id":"9","name":"CodeOnTime"},
      {"id":"10","name":"Cognos"},
      {"id":"11","name":"Control-m"},
      {"id":"12","name":"Crystal Reports"},
      {"id":"13","name":"Datastage"},
      {"id":"14","name":"DB2"},
      {"id":"15","name":"DevOps"},
      {"id":"16","name":"Enterprise Architect"},
      {"id":"17","name":"HTML\/XML\/XSL"},
      {"id":"18","name":"Informatica PWC"},
      {"id":"19","name":"ISTQB"},
      {"id":"20","name":"Java"},
      {"id":"21","name":"Javascript\/VB Script"},
      {"id":"22","name":"Logic APP"},
      {"id":"23","name":"Mantis"},
      {"id":"24","name":"Microstrategy"},
      {"id":"25","name":"Mobile Apps"},
      {"id":"26","name":"MySQL"},
      {"id":"27","name":"Nativescript"},
      {"id":"28","name":"Oracle"},
      {"id":"29","name":"Oracle Apex"},
      {"id":"30","name":"Oracle BI"},
      {"id":"31","name":"Oracle Data Integrator"},
      {"id":"32","name":"Oracle Forms"},
      {"id":"33","name":"Oracle Publisher\/ Reports"},
      {"id":"34","name":"Oracle SOA"},
      {"id":"35","name":"Oracle Warehouse Builder"},
      {"id":"36","name":"PL\/SQL"},
      {"id":"37","name":"Postgres"},
      {"id":"38","name":"Pro*C"},
      {"id":"39","name":"Rest APIs"},
      {"id":"40","name":"Sagent"},
      {"id":"41","name":"Selenium"},
      {"id":"42","name":"SQL Server"},
      {"id":"43","name":"SQL Server Integration Services"},
      {"id":"44","name":"SQL Server Reporting Services"},
      {"id":"45","name":"Sybase"},
      {"id":"46","name":"Tableau"},
      {"id":"47","name":"UC4\/Automic"},
      {"id":"48","name":"VB .NET\/C# .NET"},
      {"id":"49","name":"VB 6.0"},
      {"id":"50","name":"Web Apps"}
      ];

      this.toolsEachData = [
        {"toolName":"SQL Server","toolId":"42"},
        {"toolName":"Oracle","toolId":"28"},
        {"toolName":"Sybase","toolId":"45"},
        {"toolName":"DB2","toolId":"14"},
        {"toolName":"Access","toolId":"1"},
        {"toolName":"MySQL","toolId":"26"},
        {"toolName":"Postgres","toolId":"37"}
        ];

        
    this.positionData = this.positionData[0];

    this.checkSelected();

    this.loadDataForm();
  }

  loadContent() {
    // this.selectedPositionId = this.store.getSelectedPosition().value;
    this.selectedPositionId = this.store.getStorage("idPosition");
    console.log("this.selectedPositionId", this.selectedPositionId);
    
    
    this.loadPositionData();
    
  }

  loadPositionData(){
    this.loading = true;
    this.errorResponse = false;
    let obj = {
      idPosition: this.selectedPositionId,
      table: "technologies"
    };

    let objTools = {
      table: "tools"
    };

    let objEachTool = {
      idPosition: this.selectedPositionId,
      table: "technologiesTools"
    };
    this.connectionService.getService("getItemData.php", obj).subscribe(data => {
      console.log("data getServiceData is:", data);
      
      if (!data.error) {
        this.positionData = data.info[0];
      
      }else{
        this.errorResponse = true;
      }
      this.loading = false;
    });

    this.connectionService.getService("getItem.php", objTools).subscribe(data => {
      console.log("data getTechTools is:", data);
      
      if (!data.error) {
        this.toolsData = data.info;

        console.log("LAS TOOLS SON: ", this.toolsData);
      
      }else{
        this.errorResponse = true;
      }
      this.loading = false;
    });

    this.connectionService.getService("getTechTools.php", objEachTool).subscribe(data => {
      console.log("data technologiesTools is:", data);
      
      if (!data.error) {
        this.toolsEachData = data.info;
        this.checkSelected();
        this.loadDataForm();
        

      }else{
        this.errorResponse = true;
      }
      this.loading = false;
    });
  }

  loadDataForm(){
    this.loading = true;
    
    this.userForm.get('techName').setValue(this.positionData.name);
    this.userForm.get('techIcon').setValue(this.positionData.icon);
    this.userForm.get('techTools').setValue(this.toolsEachData.toolId);

    
    this.loading = false;

    console.log("this.positionData.icon",this.positionData.icon);
  }


  checkSelected(){

    console.log("IN checkSelected ***** this.toolsData",this.toolsData);
    this.toolsData.forEach((item) => {
      console.log("item is", item);
      item.selected = find(this.toolsEachData, { 'toolId': item.id}) ? true: false;
      console.log("item is", item);
    });

    console.log("this.toolsData",this.toolsData);
    
    
  }
 
  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;

      this.userForm.value.id = this.positionData.id;

      console.log("this.userForm.value", this.userForm.value);
      
      this.connectionService
        .insertService(this.userForm.value, "modifyTechnology.php")
        .subscribe(data => {
          console.log("data modifyTech is:", data);
          this.loading = false;
          if (!data.error) {
            this.userForm.reset();
            this.sent = true;
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Se modificó con éxito"
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/Tecnologias"]);
            });

            
          } else {
            this.errorResponse = true;
          }
        });

        
    }
  }
}
