
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from "@angular/material";
import { ConnectionService } from "src/app/services/connection.service";
import { Router } from "@angular/router";
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { RemoveModalComponent } from '../modals/remove-modal/remove-modal.component';

@Component({
  selector: 'app-services-extra',
  templateUrl: './services-extra.component.html',
  styleUrls: ['./services-extra.component.scss']
})
export class ServicesExtraComponent implements OnInit {
  noRows: boolean = false;
  loading: boolean = true;

  additionalServices: any = [];
  additionalServices2: any = [{"id":"1","name":"Consultoría de TI"},{"id":"2","name":"Integración de Servicios"},{"id":"3","name":"Soporte a Aplicaciones"},{"id":"4","name":"Administración de Proyectos"},{"id":"5","name":"Mantenimiento de Sistemas"},{"id":"6","name":"Capacitación de personal"}];

  displayedColumns: string[] = ["name", "edit", "remove"];
  dataSource: any;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private store: StorageValuesService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadContent();
    // this.loadFake();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadFake() {
    this.loading = false;
   
    this.dataSource = new MatTableDataSource(this.additionalServices2);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.noRows = false;
  }

  loadContent() {
    
    this.loading = true;
    
    this.store.deleteStorage("idPosition");

    let obj = {
      table: "additionalServices"
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data getAdditionalServices is:", data);
        this.loading = false;
        if (!data.error) {
          this.additionalServices = data.info;
          this.dataSource = new MatTableDataSource(this.additionalServices);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }

  addNew() {
    this.router.navigate(["/AgregarServicioAdicional"]);
  }


  modifyService(idPosition) {
    console.log("idPosition in modifyService", idPosition);
    // this.store.setSelectedPosition(idPosition);
    this.store.setStorage("idPosition",idPosition);
    this.router.navigate(["/ModificarServicioAdicional"]);
  }

  removeService(idPosition): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      width: '500px',
      data: {idPosition: idPosition, table: 'additionalServices', title:'Servicio Adicional'},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.status){
        this.loadContent();
      }
      
    });
  }
}

