import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechnologiesComponent } from './technologies.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatDialogModule, MatRadioModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { AddTechComponent } from './add-tech/add-tech.component';
import { ModifyTechComponent } from './modify-tech/modify-tech.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import { AddToolsComponent } from './add-tools/add-tools.component';


@NgModule({
  declarations: [TechnologiesComponent, AddTechComponent, ModifyTechComponent, AddToolsComponent],
  exports: [TechnologiesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatRadioModule,
    MatDialogModule,
    MatCheckboxModule,
    MatListModule
  ]
})
export class TechnologiesModule { }
