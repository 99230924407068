<div class="container-fluid">

    <div>
        <app-admin-navigation [isOpen]="mobileNav" (click)="toggleSideBar()" [hidden]="!logoutbtn"></app-admin-navigation>
    </div>
    <div class="main-panel" [class.not-logged]="loginbtn">
        <app-admin-mobile-nav [isClosed]="!mobileNav" (click)="toggleSideBar()"></app-admin-mobile-nav>


        <div class="main-content pt-5" [ngClass]="{ open: mobileNav }">
            <div class="row">
                <div class="col-12">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>