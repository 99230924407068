import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightDataComponent } from './highlight-data.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [HighlightDataComponent],
  exports: [HighlightDataComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ]
})
export class HighlightDataModule { }
