
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";

import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { GeneralModalComponent } from '../../modals/general-modal/general-modal.component';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-add-position',
  templateUrl: './add-position.component.html',
  styleUrls: ['./add-position.component.scss']
})
export class AddPositionComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  successSubmition: boolean = false;
  error: {};

  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  locations: any = [];
  positions: any = [];
  noRows: boolean = false;
  fkStatus: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      positionName: ["", Validators.required],
      positionDesc: ["", Validators.required],
      positionLocation: ["", Validators.required]
    });

    this.loadContent();
    // this.loadFake();

  }

  back(){
    this.router.navigate(["/Vacantes"]);
  }



  get f() {
    return this.userForm.controls;
  }


  loadFake() {
    this.loading = false;
    this.locations = [
      { "id": "1", "city": "MONTERREY, NUEVO LEÓN", "building": "Torre Avalanz - Piso 17 y 18", "address": "Av. Batallón de San Patricio #109 Sur, Col. Valle Oriente, San Pedro Garza García, N.L.", "mapUrl": "https:\/\/goo.gl\/maps\/dj9nh1zdxu9Bz4zM8" },
      { "id": "2", "city": "TEPIC, NAYARIT", "building": "", "address": "San Luis #297 Norte, Col. Centro, Tepic, Nayarit.", "mapUrl": "https:\/\/goo.gl\/maps\/U262GxEtTPZp5HHeA" },
      { "id": "3", "city": "MÉXICO, D.F.", "building": "Plaza Carso", "address": "Calle Lago Zurich #219, Col. Ampliación Granada, Del. Miguel Hidalgo, México, D.F.", "mapUrl": "https:\/\/goo.gl\/maps\/WBhywTv5GBx5CSQGA" }
    ];
  }

  loadContent() {

    let obj = {
      table: "locations"
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data getLocations is:", data);
        this.loading = false;
        if (!data.error) {
          this.locations = data.info;

          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }


  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;

      console.log("forma", this.userForm.controls['positionLocation'].value);

      this.connectionService
      .insertService(this.userForm.value, "addPosition.php")
      .subscribe(data => {
        console.log("data addPosition is:", data);
        this.loading = false;
        if (!data.error) {
          this.userForm.reset();
          const dialogRef = this.dialog.open(GeneralModalComponent, {
            width: '500px',
            data: "Se agregó exitosamente"
          });

          dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(["/Vacantes"]);
          });
          
        } else {
          this.errorResponse = true;
        }
      });

     

    }
  }
}
