import { Component, OnInit, Output, EventEmitter, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConnectionService } from "src/app/services/connection.service";
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { FullProcessService } from 'src/app/services/full-process.service';
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { LoginService } from "../services/login.service";
import { first } from 'rxjs/operators';
import * as cryptoJS from 'crypto-js';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted: boolean;
  successSubmition: boolean = false;
  error: {};

  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;

  positions: any = [];
  emailPattern = "^[a-z0-9._%+-]{2,}@[a-z0-9.-]{2,}\.[a-z]{2,}$";

  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private dataService: LoginService,
    private connectionService: ConnectionService,
    private process: FullProcessService,
    private router: Router,
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ["", Validators.required]
    });
  }

  ngOnInit() {

  }


  // postdata() {
  //   this.errorResponse = false;

  //   this.dataService.userlogin(this.loginForm.value)
  //     .pipe(first())
  //     .subscribe(
  //       data => {
  //         const redirect = this.dataService.redirectUrl ? this.dataService.redirectUrl : '';
  //         this.router.navigate([redirect]);
  //       },
  //       error => {
  //         this.errorResponse = true;
  //       });
  // }

  
  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }


  get f() {
    return this.loginForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.loginForm.invalid) {
      console.log("invalido");
      return;
    } else {
      this.loading = true;
     
      console.log("inside submit");

      this.dataService
        .userlogin(this.loginForm.value, "login.php")
        .subscribe(data => {
          
          this.loading = false;
          
          if (!data.error) {
            this.store.setStorage("token",data.info);
            this.store.setStorage("user",data.username);
            this.getLoggedInName.emit(true);

            this.loginForm.reset();

            const redirect = this.dataService.redirectUrl ? this.dataService.redirectUrl : '';
            this.router.navigate([redirect]);           

          } else {
            this.errorResponse = true;
          }
        });


    }
  }






}
