import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConnectionService } from "src/app/services/connection.service";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
} from "@angular/material";
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { RemoveModalComponent } from '../modals/remove-modal/remove-modal.component';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {
  noRows: boolean = false;
  loading: boolean = true;
  technologies: any = [];
  technologiesTools: any = [];
  dataSource: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private store: StorageValuesService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
     this.loadContent();
    // this.loadFake();
    
  }

  loadFake() {
    this.loading = false;
    this.technologies = [
      {"id":"1","name":"Oracle Retail","icon":"icon-oracle"},
      {"id":"2","name":"Programación","icon":"icon-software"},
      {"id":"3","name":"Herramientas Reporteo","icon":"icon-report"},
      ];

     
  }

  loadContent() {

    this.loading = true;
    
    this.store.deleteStorage("idPosition");

    let obj = {
      table: "technologies"
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data getTechnologies is:", data);
        this.loading = false;
        if (!data.error) {
          this.technologies = data.info;

          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }

  addTechnology() {
    this.router.navigate(["/AgregarTecnologia"]);
  }

 

  removeTech(idPosition): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      width: '500px',
      data: {idPosition: idPosition, table: 'technologies', title:'Tecnología'},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.status){
        this.loadContent();
      }
      
    });
  }

  modifyTech(idPosition) {
    console.log("idPosition in modifyTech", idPosition);
    // this.store.setSelectedPosition(idPosition);
    this.store.setStorage("idPosition",idPosition);
    this.router.navigate(["/ModificarTecnologia"]);
  }

  getTools(idTech){
    let obj = {
      table: "technologiesTools",
      idPosition: idTech
    };
    console.log("obj", obj);

    this.connectionService
      .getService("getItemData.php", obj)
      .subscribe((data) => {
        console.log("data technologiesTools is:", data);
        this.loading = false;
        if (!data.error) {
          this.technologiesTools = data.info;

          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }
}
