

import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";

import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { CvUploadService } from 'src/app/services/cv-upload.service';
import { MatDialog } from '@angular/material';
import { GeneralModalComponent } from "../modals/general-modal/general-modal.component";
@Component({
  selector: 'app-terms-privacy',
  templateUrl: './terms-privacy.component.html',
  styleUrls: ['./terms-privacy.component.scss']
})
export class TermsPrivacyComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  successSubmition: boolean = false;
  error: {};
  
  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  uploadResponse;
  errorSubmition: boolean = false;
  privacyData:any = [];


  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router,
    private cvUpload: CvUploadService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    
    this.userForm = this.formBuilder.group({
      id: ["1", Validators.required],
      privacyName: ["", Validators.required],
      userUpload: ["", Validators.required],
      hLImage: [""],  
    });
    
    // this.loadFake();
    this.loadPositionData();
      
  }

  loadFake(){
    
    this.privacyData = [
      {id:"1",label:"Aviso de Privacidad",url:"https://dotechmx.com/backend/services/uploads/945748-carta2.pdf"}
      ];
    this.privacyData = this.privacyData[0];
    console.log("data getprivacyData is:", this.privacyData);


    this.loadDataForm();
  }

  loadPositionData(){
    this.loading = true;
    this.errorResponse = false;
    let obj = {
      idPosition: 1,
      table: "termsPrivacy"
    };
    this.connectionService.getService("getItemData.php", obj).subscribe(data => {
      console.log("data termsPrivacy is:", data);
      
      if (!data.error) {
        this.privacyData = data.info[0];
        
        this.loadDataForm();
        

      }else{
        this.errorResponse = true;
      }
      this.loading = false;
    });
  }

  loadDataForm(){
    this.loading = true;
    
    this.userForm.get('privacyName').setValue(this.privacyData.label);
    console.log("setting data");
    this.loading = false;
  }



  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userForm.get("userUpload").setValue(file);
    }
  }

  back(){
    this.router.navigate([""]);
  }


  get f() {
    return this.userForm.controls;
  }

  displayFn(item) {
    if (item) {
      return item.nombre;
    }
  }


  onSubmit() {
    this.submitted = true;

    // TODO: Use EventEmitter with form value
    console.log(this.userForm.value);

    if (this.userForm.invalid) {
      console.log("still invalid");
      return;
    } else {
      console.log("ok lets go!");
      this.loading = true;

      //first upload CV
      const formData = new FormData();
      formData.append("userUpload", this.userForm.get("userUpload").value);

      console.log("formData", formData);

      return this.cvUpload.uploadFile(formData).subscribe(
        (res) => {
          this.uploadResponse = res;
          console.log("result is", res);

          //If Cv was uploaded, then send information about candidate
          if (!res.error) {
            this.userForm.get("hLImage").setValue(res.url);
            console.log("FORM TO GO", this.userForm.value);

            this.connectionService
              .insertService(this.userForm.value, "modifyTermsPrivacy.php")
              .subscribe((data) => {
                
                  if (!data.error) {
                  this.userForm.reset();
                  this.loading = false;
                  
                  const dialogRef = this.dialog.open(GeneralModalComponent, {
                    width: '500px',
                    data: "Se modificó exitosamente"
                  });

                  dialogRef.afterClosed().subscribe(result => {
                    this.router.navigate(["/Inicio"]);                 
                  });
                  
                }else{
                  this.loading = false;
                  this.errorSubmition = true;
                }
              });
          }else{
            this.loading = false;
            this.errorSubmition = true;
          }

        },
        (err) => {
          this.loading = false;
          console.log("error is", err);
        }
      );

     
    }
  }

 


}

