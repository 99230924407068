import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsPrivacyComponent } from './terms-privacy.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [TermsPrivacyComponent],
  exports: [TermsPrivacyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ]
})
export class TermsPrivacyModule { }
