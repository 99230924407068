import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ribbon-years',
  templateUrl: './ribbon-years.component.html',
  styleUrls: ['./ribbon-years.component.scss']
})
export class RibbonYearsComponent implements OnInit {

  userForm: FormGroup;
  submitted: boolean;
  successSubmition: boolean = false;
  error: {};
  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  editMode: boolean = false;
  ribbonData: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router) { }

  ngOnInit() {
  
    this.loadContent();
      // this.loadFake();
  }
  

  loadFake(){
    
    this.ribbonData = [
      {"id":"1","years":"9","yearsDesc":"Años de Experiencia","devs":"120", "devsDesc":"Colaboradores"}
      ];
    this.ribbonData = this.ribbonData[0];
    console.log("data ribbonData is:", this.ribbonData);

  }

  loadContent(){
    
    this.loading = true;

    this.store.deleteStorage("idPosition");

    this.errorResponse = false;
    let obj = {
      idPosition: '1',
      table: "ribbonData"
    };

    this.connectionService.getService("getItemData.php", obj).subscribe(data => {
      console.log("data getRibbonData is:", data);
      
      if (!data.error) {
        this.ribbonData = data.info[0];
      }else{
        this.errorResponse = true;
      }
      this.loading = false;
    });
  }


  modify(){
    this.router.navigate(["/ModificarIndicadores"]);
  }




 
}
