import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { WINDOW_PROVIDERS } from './services/window.service';
import { MatDialogModule } from '@angular/material/dialog';
import { AdminMobileNavModule } from './components/navigation/admin-mobile-nav/admin-mobile-nav.module';
import { AdminNavigationModule } from './components/navigation/admin-navigation/admin-navigation.module';
import { AdminContactsModule } from './components/contacts/admin-contacts/admin-contacts.module';
import { AdminCandidatesModule } from './components/candidates/admin-candidates/admin-candidates.module';
import { AdminPositionsModule } from './components/positions/admin-positions/admin-positions.module';
import { ModifyPositionModule } from './components/positions/modify-position/modify-position.module';
import { AddPositionModule } from './components/positions/add-position/add-position.module';
import { RemoveModalModule } from './components/modals/remove-modal/remove-modal.module';
import { HighlightDataModule } from './components/highlight-data/highlight-data.module';
import { ServicesModule } from './components/services/services.module';
import { TechnologiesModule } from './components/technologies/technologies.module';
import { ServicesExtraModule } from './components/services-extra/services-extra.module';
import { RibbonYearsModule } from './components/ribbon-years/ribbon-years.module';
import { LocationsModule } from './components/locations/locations.module';
import { ClientsModule } from './components/clients/clients.module';
import { GeneralModalComponent } from './components/modals/general-modal/general-modal.component';
import { GeneralModalModule } from './components/modals/general-modal/general-modal.module';
import { LoginModule } from './login/login.module';
import { ToolsModule } from './components/tools/tools.module';
import { LoginComponent } from './login/login.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { TermsPrivacyModule } from './components/terms-privacy/terms-privacy.module';
import { ChangePasswordModule } from './components/change-password/change-password.module';




@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    LoginModule,
    AdminMobileNavModule,
    AdminNavigationModule,
    AdminContactsModule,
    AdminCandidatesModule,
    AdminPositionsModule,
    ModifyPositionModule,
    AddPositionModule,
    RemoveModalModule,
    HighlightDataModule,
    ServicesModule,
    TermsPrivacyModule,
    ServicesExtraModule,
    TechnologiesModule,
    ToolsModule,
    RibbonYearsModule,
    LocationsModule,
    ClientsModule,
    GeneralModalModule,
    DashboardModule,
    ChangePasswordModule

  ],
  providers: [WINDOW_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }
