
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConnectionService } from "src/app/services/connection.service";

import { Router } from '@angular/router';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { GeneralModalComponent } from "../../modals/general-modal/general-modal.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: 'app-modify-ribbon',
  templateUrl: './modify-ribbon.component.html',
  styleUrls: ['./modify-ribbon.component.scss']
})
export class ModifyRibbonComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean;
  error: {};
  selectedPositionId: any;

  loading: boolean = false;
  errorResponse: boolean = false;
  sent: boolean = false;
  positions: any = [];
  positionData: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private store: StorageValuesService,
    private connectionService: ConnectionService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      id: ["", ""],
      years: ["", Validators.required],
      yearsDesc: [""],
      devs: ["", Validators.required],
      devsDesc: ["", Validators.required]
      });

    this.loadContent();
    // this.loadFake();
  }

  back(){
    this.router.navigate(["/Indicadores"]);
  }


  get f() {
    return this.userForm.controls;
  }

  displayFn(item) {
    if (item) {
      return item.nombre;
    }
  }




  loadFake(){
    
    this.selectedPositionId = '2';
    this.positionData = [
      { "id": "1", "years": "9", "yearsDesc": "Años de Experiencia", "devs": "120", "devsDesc": "Colaboradores" }
      ];
    this.positionData = this.positionData[0];
    console.log("data getpositionData is:", this.positionData);

    this.loadDataForm();
  }



  loadContent(){
    this.loading = true;
    this.errorResponse = false;
    let obj = {
      idPosition: '1',
      table: "ribbonData"
    };
    this.connectionService.getService("getItemData.php", obj).subscribe(data => {
      console.log("data getPositionData is:", data);
      
      if (!data.error) {
        this.positionData = data.info[0];
        
        this.loadDataForm();
        

      }else{
        this.errorResponse = true;
      }
      this.loading = false;
    });
  }

  loadDataForm(){
    this.loading = true;
    this.userForm.get('years').setValue(this.positionData.years);
    this.userForm.get('yearsDesc').setValue(this.positionData.yearsDesc);
    this.userForm.get('devs').setValue(this.positionData.devs);
    this.userForm.get('devsDesc').setValue(this.positionData.devsDesc);
    
    this.loading = false;
  }


 
  onSubmit() {
    this.submitted = true;
    this.errorResponse = false;

    if (this.userForm.invalid) {
      return;
    } else {
      this.loading = true;

      this.userForm.value.id = this.positionData.id;

      console.log("this.userForm.value", this.userForm.value);
      
      this.connectionService
        .insertService(this.userForm.value, "modifyRibbon.php")
        .subscribe(data => {
          console.log("data modifyLocation is:", data);
          this.loading = false;
          if (!data.error) {
            this.userForm.reset();
            this.sent = true;
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
              data: "Se modificó con éxito"
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(["/Indicadores"]);
            });
          } else {
            this.errorResponse = true;
          }
        });

        
    }
  }
}
