<section>
    <div>
        <div class="container">
            <div class="loading" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <h2 class="subhead-title">Modificar Vacante</h2>
                </div>
            </div>
            <div class="row">
                <div class="offset-lg-2 col-lg-8 offset-md-1 col-md-10">

                    <div class="alert alert-danger" role="alert" *ngIf="errorResponse">
                        Hubo un problema, intentalo más tarde.
                    </div>
                    <form class="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()" *ngIf="!sent">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="positionName">Nombre de Vacante</label>
                                    <input formControlName="positionName" type="text" class="form-control" id="positionName" required />

                                    <div *ngIf="submitted && f.positionName.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.positionName.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label for="positionDesc">Descripción</label>
                                    <textarea formControlName="positionDesc" type="text" class="form-control" id="positionDesc" required></textarea>

                                    <div *ngIf="submitted && f.positionDesc.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.positionDesc.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Ubicación</label>

                                    <div>
                                        <mat-radio-group formControlName="positionLocation" aria-label="Selecciona" required>
                                            <mat-radio-button class="services-radio d-inline-block" [value]="each.id" [checked]="each.id == positionData.idLocation" *ngFor="let each of locations">
                                                {{each.city}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div *ngIf="submitted && f.positionLocation.errors" class="d-block invalid-feedback">
                                        <div *ngIf="f.positionLocation.errors.required">
                                            *Campo Requerido
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12">
                                <button type="submit" class="btn red mt-3">Modificar</button>
                                <button class="btn m-auto red-outline" (click)="back()">Cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>