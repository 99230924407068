import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { StorageValuesService } from '../services/storage-values.service';

@Injectable({
  providedIn: 'root'
})

export class AuthguardGuard implements CanActivate {
  constructor(private dataService: LoginService, private router: Router,private store: StorageValuesService) { }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const routeurl: string = state.url;
    return this.isLogin(routeurl);
  }

  isLogin(routeurl: string) {
    if (this.store.isLoggedIn('token')) {
      return true;
    }

    this.dataService.redirectUrl = routeurl;
    this.router.navigate(['/Login'], { queryParams: { returnUrl: routeurl } });
  }
}