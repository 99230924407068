<section>
    <div>
        <div class="container">
            <div class="loading" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <h2 class="subhead-title">Modificar Cliente</h2>
                </div>
            </div>
            <div class="row">
                <div class="offset-lg-2 col-lg-8 offset-md-1 col-md-10">

                    <div class="alert alert-danger" role="alert" *ngIf="errorResponse">
                        Hubo un problema, intentalo más tarde.
                    </div>
                    <form class="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()" *ngIf="!sent">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">



                                    <label for="userUpload">Imagen</label>
                                    <ul class="text-secondary">
                                        <li>Formato PNG o JPG</li>
                                        <li>Tamaño máximo 1MB</li>
                                    </ul>
                                    <div>

                                        <input type="file" (change)="onFileSelect($event)" placeholder="Logotipo" name="userUpload" />

                                        <div *ngIf="submitted && f.userUpload.errors" class="d-block invalid-feedback">
                                            <div *ngIf="f.userUpload.errors.required">
                                                *Campo Requerido
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="col-12">
                                <button type="submit" class="btn red mt-3">Modificar</button>
                                <button class="btn m-auto red-outline" (click)="back()">Cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>